@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,700&display=swap");

:root {
  --white: #fff;
  --green: #3e885f;
  --red: #ff0266;
  --blue: #3c4b8a;
  --yellow: #b69339;
  --cyan: #3195b5;
  --green-1: #218838;
  --red-1: #c82333;
  --blue-1: #313d7d;
  --yellow-1: #e0a800;
  --cyan-1: #138496;
  --font-primary: "Nunito Sans", sans-serif;
  --color-1: #1614FF;
  --color-2: #3c4b9a;
  --color-3: #f5a001;
  --color-4: #743c6e;
  --color-5: #4b5480;
  --color-7: #1A1A1A;
  --color-8: #af67a4;
  --color-9: #66bcd6;
  --color-10: #a39bfe;
  --color-11: #efa07c;
  --color-1-1: #795d75;
  --color-7-1-: #565555;
}

.dark {
  --bg-main: #1A1A1A;
  --bg-component: #333;
  --border-color: rgba(255, 255, 255, 0.12);
  --border-component: #3333;
  --box-shadow: rgba(0, 0, 0, 0.2);
  --box-shadow-1: rgba(0, 0, 0, 0.05);
  --text-color: #fff;
  --light-text-color: rgba(255, 255, 255, 0.7);
}

.light {
  --bg-main: #f8f8f8;
  --bg-component: #ffffff;
  --border-color: #d7d7d7;
  --border-component: #f3f3f3;
  --box-shadow: rgba(0, 0, 0, 0.1);
  --box-shadow-1: rgba(0, 0, 0, 0.04);
  --text-color: #303030;
  --light-text-color: #30303080;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 20px;
}

html,
body {
  background-color: var(--bg-main);
  color: var(--text-color);
  text-transform: uppercase;
  font-family: var(--font-primary);
  font-size: 13px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

[class^="col-"] .card {
  height: 100%;
}

a {
  color: var(--text-color);
  cursor: pointer;
  transition: color ease-in-out 300ms;
}

a:hover,
a:focus,
a:active {
  color: var(--color-1);
  text-decoration: none;
  transition: color ease-in-out 300ms;
}

.card {
  box-shadow: 0 1px 15px var(--box-shadow-1), 0 1px 8px var(--box-shadow-1);
  border-radius: 10px;
  margin-bottom: 15px;
  transition: all ease-in-out 300ms;
}

.card:hover {
  box-shadow: 0 10px 30px var(--box-shadow-1), 0 10px 16px var(--box-shadow-1);
  transition: all ease-in 300ms;
}

.card .card-header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card-icon {
  border-radius: 50%;
  border: 1px solid var(--light-text-color);
  display: none;
  color: var(--color-1);
  height: 35px;
  width: 35px;
  line-height: 38px;
  text-align: center;
  font-size: 1.2rem;
}

.list-group-item,
.card,
.modal-content,
.tab-pane,
.form-control,
.jumbotron,
.custom-file-label,
.custom-select,
.popover,
.popover-header,
.popover-body,
input,
body .react-tagsinput,
.input-group-text {
  background-color: var(--bg-component);
  color: inherit;
  border-color: var(--border-color);
}

.list>* {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
  transition: border ease-in-out 300ms;
  margin: 0;
}

.list>*:hover {
  border-color: var(--color-1);
  transition: border ease-in-out 300ms;
}

.rv-hint {
  background: var(--bg-main);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 14px var(--box-shadow);
}

.list-group {
  flex-wrap: wrap;
}

.close {
  color: var(--text-color);
}

.sidebar-expanded .left-navigation {
  width: 250px;
}

.sidebar-expanded #main-content {
  left: 250px;
}

.sidebar-expanded .navbar {
  left: 250px;
  transition: all ease-in-out 250ms;
}

.sidebar-toggle,
.sidebar-toggle:hover,
.sidebar-toggle:focus {
  padding: 2px 9px;
  font-size: 16px;
  margin: -4px 4px 0 0;
  color: var(--text-color);
  top: 20px;
  left: 5px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  text-align: center;
}

.w-20 {
  width: 20%;
}

body.sidebar-expanded .sidebar-toggle {
  transform: rotate(180deg);
}

body.sidebar-expanded .navbar-brand .logo {
  display: inline-block;
  width: 140px;
  vertical-align: text-top;
}

.light .navbar-brand .logo {
  filter: invert();
}

.left-navigation .navbar-brand .logo {
  display: none;
}

.left-navigation .navbar-brand .icon {
  width: 50px;
  margin: -8px;
}

.left-navigation svg *,
.navbar svg * {
  fill: var(--text-color);
  color: var(--text-color);
}

body:not(.sidebar-expanded) section.left-navigation:hover {
  width: 250px;
}

body:not(.sidebar-expanded) section.left-navigation:hover .label {
  display: inline-block;
}

body:not(.sidebar-expanded) section.left-navigation:hover .logo {
  display: inline-block;
  width: 100px;
  vertical-align: text-top;
  margin-left: 10px;
  margin-top: 3px;
}

#main-content {
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 70px;
  right: 0;
  overflow: auto;
  padding: 0;
  transition: all ease-in-out 250ms;
}

#notice {
  position: fixed;
  margin: 0 auto;
  max-width: 50vw;
  min-width: 100px;
  max-height: 100px;
  z-index: 999999;
  display: block;
  width: 600px;
  text-transform: capitalize;
  box-shadow: 0 2px 2px 0 var(--box-shadow-1);
}

#notice.topLeft {
  top: 10px;
  left: 10px;
}

#notice.topRight {
  top: 10px;
  right: 10px;
}

#notice.bottomLeft {
  bottom: 10px;
  left: 10px;
}

#notice.bottomRight {
  bottom: 10px;
  right: 10px;
}

#notice.topCenter {
  top: 10px;
  left: 0;
  right: 0;
  bottom: auto;
}

#notice.bottomCenter {
  top: auto;
  left: 0;
  right: 0;
  bottom: 10px;
}

#notice .float-left {
  width: calc(100% - 30px);
}

#notice.alert-danger {
  color: white;
  background-color: var(--red);
  border-color: var(--red);
}

#notice.alert-info {
  color: white;
  background-color: var(--cyan);
  border-color: var(--cyan);
}

#notice.alert-success {
  color: white;
  background-color: var(--green);
  border-color: var(--green);
}

#notice.alert-danger .close {
  background: var(--red);
}

#notice.alert-success .close {
  background: var(--green);
}

#notice .close {
  font-size: inherit;
  line-height: normal;
  float: right;
  width: 60px;
  margin: 0;
  cursor: pointer;
  text-shadow: none;
  text-align: center;
  padding: 5px;
  border-radius: 20px;
  background: #0090b3;
  color: white;
  opacity: 1;
}

#notice .notice-text {
  width: calc(100% - 130px);
  float: left;
  padding: 2px;
}

.page-icon * {
  color: var(--text-color);
  fill: var(--text-color);
}

input {
  background-color: var(--bg-component);
  border-radius: 6px;
  border: 0px;
  color: var(--text-color);
  padding: 0 1.25rem;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid var(--border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input:focus {
  outline: none;
  box-shadow: none;
}

.custom-flex {
  display: flex;
  flex-direction: column;
}

.custom-flex>.card {
  flex: 1;
  overflow: auto;
}

.custom-select-container {
  position: relative;
  width: 100%;
}

.multi-select .dropdown .dropdown-content .select-panel input[type="checkbox"] {
  filter: hue-rotate(33deg) contrast(3) saturate(0.4);
}

.custom-select-container select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  text-indent: 1px !important;
  text-overflow: "" !important;
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 5px 10px;
  font-family: var(--font-primary);
  font-size: 0.875rem;
}

.custom-select-container select:focus,
.custom-select-container select:active,
.custom-select-container select:hover {
  box-shadow: none;
  border-color: var(--color-1);
  background-color: transparent;
}

.custom-select-container .custom-select-input-arrow {
  color: var(--text-color);
  transform: rotate(225deg);
  font-family: cursive;
  position: absolute;
  right: 7px;
  top: 8px;
  background-color: var(--bg-component);
  bottom: 0;
  height: 16px;
  width: 20px;
}

label {
  font-size: 12px;
  margin-bottom: 0px;
  line-height: 1.8;
  letter-spacing: normal;
  color: var(--light-text-color);
}

.changepassword-section h2 {
  margin-top: 20%;
}

.changepassword-section input {
  margin-bottom: 20px;
}

.changepassword-section form button {
  margin-top: 2.5rem;
  border-radius: 2px;
  box-shadow: 0 0 20px 0 var(--box-shadow-1);
  background-color: var(--color-1);
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  color: var(--white);
  text-transform: uppercase;
  padding: 10px 0;
}

a.change {
  text-transform: capitalize;
  color: var(--text-color);
  font-size: 13px;
}

a.change:hover {
  background: var(--light-text-color);
  color: var(--text-color);
  text-decoration: none;
}

a.backbtn {
  color: var(--white) !important;
}

a.backbtn:hover {
  color: var(--white) !important;
  text-decoration: none;
}

.nav-tabs {
  border-bottom: 1px solid var(--border-color);
}

.nav-tabs .nav-link {
  cursor: pointer;
  border-color: transparent;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:active,
.nav-tabs .nav-link.active {
  background: transparent;
  color: var(--color-1);
  border-width: 0 0 2px 0;
  border-color: var(--color-1);
  font-weight: bold;
}

.tab-pane {
  padding: 1em;
  background-color: var(--bg-main);
}

.btn-primary {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: white;
  line-height: normal;
  font-weight: normal;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--color-4);
}

.btn-secondary {
  background-color: var(--color-7);
  border-color: var(--color-7);
  line-height: normal;
  font-weight: normal;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background-color: var(--color-1);
}

.btn-success {
  background-color: var(--green);
  border-color: var(--green);
  line-height: normal;
  font-weight: normal;
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  background-color: var(--green-1);
}

.btn-warning {
  background-color: var(--yellow);
  border-color: var(--yellow);
  line-height: normal;
  font-weight: normal;
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
  background-color: var(--yellow-1);
}

.btn-danger {
  background-color: var(--red);
  border-color: var(--red);
  line-height: normal;
  font-weight: normal;
}

.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
  background-color: var(--red-1);
}

.btn-info {
  background-color: var(--cyan);
  border-color: var(--cyan);
  line-height: normal;
  font-weight: normal;
}

.btn-info:hover,
.btn-info:active,
.btn-info:focus {
  background-color: var(--cyan-1);
}

.text-primary {
  color: var(--color-1) !important;
  background: transparent !important;
}

.text-success {
  color: var(--green) !important;
  background: transparent !important;
}

.text-warning {
  color: var(--yellow) !important;
  background: transparent !important;
}

.text-danger {
  color: var(--red) !important;
  background: transparent !important;
}

.text-info {
  color: var(--cyan) !important;
  background: transparent !important;
}

.dropdown>button.btn {
  background-color: transparent;
  width: 100%;
  border-radius: 50px;
  border: 1px solid var(--border-color);
  text-align: left;
  position: relative;
  padding: 5px 10px;
  color: var(--text-color);
  font-size: 13px;
}

.dropdown>button.btn:hover,
.dropdown>button.btn:focus,
.dropdown>button.btn:active {
  border-color: var(--color-1);
  color: var(--text-color);
  background-color: transparent;
}

.dropdown>button.btn::after {
  position: absolute;
  right: 10px;
  top: 50%;
}

.dropdown .dropdown-menu {
  box-shadow: 0 2px 20px 0 var(--box-shadow-1);
  background-color: var(--bg-component);
  min-width: 250px;
}

.dropdown .dropdown-menu button {
  color: var(--text-color);
  font-size: 13px;
}

.dropdown .dropdown-menu button:hover,
.dropdown .dropdown-menu button:focus {
  background-color: var(--light-text-color);
}

.multi-select .dropdown .dropdown-heading {
  background-color: transparent !important;
  border-radius: 0px !important;
  border: unset !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.multi-select .dropdown .dropdown-heading .dropdown-heading-value span {
  color: var(--text-color);
}

.multi-select .dropdown .dropdown-content {
  border: 0px !important;
  box-shadow: 0 4px 30px 0 var(--box-shadow-1) !important;
  overflow-x: hidden !important;
  background-color: var(--bg-component) !important;
}

.multi-select .dropdown .dropdown-content .select-panel {
  background-color: var(--bg-component) !important;
}

.multi-select .dropdown .dropdown-content .select-panel input {
  border: 0px !important;
  border-radius: 6px;
  background-color: var(--border-color);
  font-weight: bolder;
  font-size: 13px;
  width: auto;
}

.multi-select .dropdown .dropdown-content .select-panel label {
  background-color: var(--bg-component) !important;
  border-bottom: 1px solid var(--border-color);
}

.multi-select .dropdown .dropdown-content .select-panel label .item-renderer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.multi-select .dropdown .dropdown-content .select-panel label .item-renderer span {
  font-size: 13px !important;
  color: var(--text-color);
  line-height: normal;
}

.search-wrapper {
  position: relative;
}

.search-wrapper form {
  width: 300px;
}

.search-wrapper .search-bar input {
  border-radius: 20px;
  padding-right: 30px;
}

.search-wrapper .search-bar input:focus,
.search-wrapper .search-bar input:hover {
  border: 1px solid var(--color-1);
  color: var(--color-1);
  box-shadow: none;
  outline: none;
}

.search-wrapper .search-bar input:focus~.search-icon,
.search-wrapper .search-bar input:hover~.search-icon {
  color: var(--color-1);
}

.search-wrapper .search-bar {
  position: relative;
}

.search-wrapper .search-bar .search-icon {
  position: absolute;
  right: 15px;
  top: 10px;
  color: var(--light-text-color);
}

.search-wrapper .search-container {
  z-index: 9;
  position: absolute;
  border: 1px solid var(--border-color);
  right: 0;
  width: 750px;
  border-radius: 10px;
  top: 55px;
  box-shadow: 0 1px 15px var(--box-shadow), 0 1px 8px var(--box-shadow);
  background: var(--bg-main);
  padding: 2rem;
  height: 600px;
  overflow: hidden;
  visibility: hidden;
  transition: visibility ease 300ms;
}

.search-wrapper .search-results {
  overflow: auto;
  height: calc(100% - 30px);
}

.search-wrapper.active .search-container {
  visibility: visible;
  transition: visibility ease 300ms;
}

form label {
  margin-top: 15px;
  display: block;
}

form fieldset {
  background-color: var(--bg-main);
  padding: 1rem;
  border-radius: 4px;
}

form small {
  font-size: 75%;
  color: var(--red);
}

form .form-control {
  font-size: 13px;
  line-height: 1.57;
  color: var(--text-color);
  background: var(--bg-component);
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  transition: border ease-in-out 300ms;
}

form .form-group {
  margin-bottom: 1.5rem;
}

form label {
  font-size: inherit;
}

form .form-control:focus,
form .form-control:active,
form .form-control:hover {
  border-color: var(--color-1);
  transition: border ease-in-out 300ms;
}

.pace {
  pointer-events: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #29d;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.sign-in-component {
  background-color: var(--light-text-color);
  color: var(--text-color);
  height: 100vh;
  width: 100vw;
}

.sign-in-component .sign-in-row {
  height: 100%;
  text-align: center;
}

.sign-in-component .sign-in-row .sign-in-left-col {
  height: 100%;
  background-color: var(--bg-component);
  background-image: url("../images/wave.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom -10%;
}

.sign-in-component .sign-in-row .sign-in-left-col .logo-img {
  max-width: 12.5rem;
}

.sign-in-component .sign-in-row .sign-in-left-col .hr-line-sign-in {
  box-shadow: 0 0 30px 5px var(--box-shadow-1);
  width: 2.5rem;
  height: 1px;
  border: 1px solid var(--color-1);
  margin: 1.3rem auto;
}

.sign-in-component .sign-in-row .sign-in-left-col h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.sign-in-component .sign-in-row .sign-in-left-col h3 {
  margin-top: 0.8125rem;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0 2rem;
}

.sign-in-component .sign-in-row .sign-in-right-col h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.sign-in-component .sign-in-row .sign-in-right-col form input {
  margin-top: 2.5rem;
  background-color: var(--border-color);
  border-radius: 6px;
  border: 0px;
  min-height: 50px;
  font-size: 16px;
  color: var(--text-color);
  padding: 0 1.25rem !important;
}

.sign-in-component .sign-in-row .sign-in-right-col form input::-webkit-input-placeholder {
  font-size: 16px;
  color: var(--light-text-color);
}

.sign-in-component .sign-in-row .sign-in-right-col form input:-ms-input-placeholder {
  font-size: 16px;
  color: var(--light-text-color);
}

.sign-in-component .sign-in-row .sign-in-right-col form input::-ms-input-placeholder {
  font-size: 16px;
  color: var(--light-text-color);
}

.sign-in-component .sign-in-row .sign-in-right-col form input::placeholder {
  font-size: 16px;
  color: var(--light-text-color);
}

.sign-in-component .sign-in-row .sign-in-right-col form input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.sign-in-component .sign-in-row .sign-in-right-col form button {
  margin-top: 2.5rem;
  border-radius: 2px;
  box-shadow: 0 0 20px 0 var(--box-shadow-1);
  background-color: var(--color-1);
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  color: var(--white);
  text-transform: uppercase;
  padding: 10px 0;
}

.sign-in-component .sign-in-row .sign-in-right-col .forget-password {
  margin-top: 1.5625rem;
}

.sign-in-component .sign-in-row .sign-in-right-col .forget-password a {
  font-size: 1rem;
  color: var(--text-color);
  text-decoration: underline;
}

.navbar {
  background-color: var(--bg-component);
  box-shadow: 0 1px 15px var(--box-shadow), 0 1px 8px var(--box-shadow);
  min-height: 72px;
  left: 71px;
  z-index: 998;
}

.navbar-brand {
  max-height: 55px;
  height: 55px;
  margin-top: 10px;
}

.navbar .navbar-brand img {
  max-width: 114px;
}

.navbar .profile-image {
  margin-left: 40px;
  cursor: pointer;
}

.navbar .profile-image img {
  height: 36px;
  width: 36px;
  border-radius: 100px;
  background-color: grey;
  -o-object-fit: cover;
  object-fit: cover;
}

.table {
  color: var(--text-color);
}

.table-hover tbody tr:hover {
  color: var(--light-text-color);
}

.table .thead-light th,
.table tbody td,
.table tbody th,
.dropdown-item {
  background-color: var(--bg-component);
  border-color: var(--border-color);
  color: var(--text-color);
  vertical-align: middle;
}

.header-placeholder {
  height: 70px;
  position: relative;
}

.left-navigation {
  position: fixed;
  background: var(--bg-component);
  box-shadow: 0 3px 30px var(--box-shadow), 0 3px 24px var(--box-shadow);
  width: 70px;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 250ms;
  z-index: 999;
}

.left-navigation ul {
  margin: 0;
  padding-top: 20px;
}

.left-navigation ul li {
  margin-top: 20px;
}

.left-navigation ul li a {
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 5px 15px;
  border-left: 4px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
}

.left-navigation ul li a span.label {
  margin-left: 10px;
  font-size: 13px;
  display: none;
}

.sidebar-expanded .left-navigation ul li a span.label {
  display: inline-block;
}

.left-navigation ul li a svg {
  height: 24px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  vertical-align: middle;
}

.left-navigation ul li a:hover,
.left-navigation ul li a:focus,
.left-navigation ul li a.active {
  border-left: 4px solid var(--color-1);
  border-radius: 0px;
  font-weight: bold;
  color: var(--color-1);
  fill: var(--color-1);
}

.left-navigation ul li a.active * {
  color: var(--color-1);
  fill: var(--color-1);
}

.user-panel {
  width: 488px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-component);
  z-index: 2001;
  padding: 55px;
  transform: translateX(100%);
  transition: all 500ms ease-out;
  overflow: auto;
}

.user-panel.open {
  transform: translateX(0%);
}

.user-panel header {
  font-weight: bolder;
  font-size: 20px;
  color: var(--text-color);
}

.user-panel header .close-panel {
  float: right;
  color: var(--text-color);
  cursor: pointer;
}

.user-panel .profile-image-upload .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}

.user-panel .profile-image-upload .avatar-upload .avatar-edit {
  position: absolute;
  right: 15px;
  z-index: 1;
  top: 130px;
}

.user-panel .profile-image-upload .avatar-upload .avatar-edit input {
  display: none;
}

.user-panel .profile-image-upload .avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: transparent;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px var(--box-shadow-1);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.user-panel .profile-image-upload .avatar-upload .avatar-edit input+label:after {
  content: "";
  background-image: url("../images/camera.svg");
  background-repeat: no-repeat;
  background-position: center;
  color: var(--text-color);
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  width: 40px;
  height: 40px;
}

.user-panel .profile-image-upload .avatar-upload .edit-button {
  color: white;
  position: absolute;
  top: 20px;
  right: 0;
  text-align: center;
  width: 40px;
  height: 40px;
  background: var(--color-1);
  border: none;
  border-radius: 50%;
}

.user-panel .profile-image-upload .avatar-upload .avatar-preview {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow-1);
  overflow: hidden;
}

.user-panel .profile-image-upload .avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  z-index: 2000;
  opacity: 0.5;
  transition: all 500ms ease-out;
}

.count {
  font-weight: bolder;
  font-size: 1.6rem;
  margin: 0;
  text-transform: capitalize;
}

.rise-fall-per {
  font-size: 0.875rem;
  font-weight: bold;
  position: relative;
  vertical-align: super;
}

.rise-fall-per .rise {
  color: var(--green);
  vertical-align: middle;
  height: 20px;
}

.rise-fall-per .rise::before {
  content: "\2191";
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  background: rgba(62, 136, 95, 0.3);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  font-weight: bold;
  font-family: monospace;
  font-size: 16px;
  line-height: 20px;
}

.rise-fall-per .fall {
  color: var(--red);
  vertical-align: middle;
  height: 20px;
}

.rise-fall-per .fall::before {
  content: "\2193";
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  background: rgba(196, 61, 91, 0.3);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  font-weight: bold;
  font-family: monospace;
  font-size: 16px;
  line-height: 20px;
}

body .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background: initial;
  color: var(--color-1);
}

.DayPickerInput-OverlayWrapper {
  z-index: 2;
}

.DayPicker .DayPicker-Months {
  flex-wrap: nowrap;
}

.color-cir {
  width: 15px;
  height: 15px;
  background-color: var(--green);
  border-radius: 100%;
  margin-right: 10px;
}

.common-count {
  position: absolute;
  top: 102px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  color: white;
}

.left-cir {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: var(--blue);
  opacity: 0.7;
  border-radius: 100%;
  display: inline-block;
  color: white;
}

.left-cir .count {
  top: 50%;
  position: absolute;
  left: 30%;
  transform: translate(-30%, -50%);
  font-size: 1rem;
}

.right-cir {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: var(--red);
  opacity: 0.7;
  border-radius: 100%;
  margin-left: -100px;
  display: inline-block;
  color: white;
}

.right-cir .count {
  top: 50%;
  position: absolute;
  right: 30%;
  transform: translate(-30%, -50%);
  font-size: 1rem;
}

.scrollable-container {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 2rem 1rem;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
}

.scrollable-container .details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 50%));
  font-size: 12px;
  gap: 1rem 0;
  align-items: stretch;
}

.scrollable-container .details small:nth-child(even) {
  text-align: right;
}

.scrollable-container.list-view {
  display: flex;
  margin: 25px 0;
  flex-flow: row;
  overflow-x: auto;
  position: relative;
}

.scroll-wrapper .navigation {
  position: absolute;
  padding: 15px;
  font-size: 20px;
  background-color: var(--bg-component);
  border: 1px solid var(--border-component);
  top: 20%;
  border-radius: 4px;
  z-index: 1;
  display: none;
}

.scroll-wrapper {
  position: relative;
}

.scroll-wrapper .navigation.left {
  left: 0;
}

.scroll-wrapper .navigation.right {
  right: 0;
}

.scrollable-container>* {
  pointer-events: auto;
}

/* .scrollable-container:hover .card:not(:hover) {
  filter: blur(2px) opacity(0.65);
  transform: scale(0.75);
}

.scroll-wrapper:hover .navigation {
  display: block;
} */

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.scrollable-container .card {
  min-width: 350px;
  max-width: 360px;
  margin: 10px 15px;
  overflow: hidden;
  border-radius: 8px;
  color: var(--text-color);
  transition: all ease 300ms;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1px;
  background-color: var(--bg-component);
  background-clip: content-box;
  border: none;
}

.scrollable-container .card img {
  height: 202px;
}

.previewer {
  justify-content: center;
}

.previewer .card {
  max-width: 300px;
  max-height: 500px;
}

.card.multiple {
  box-shadow: 0 1px 1px rgb(0 0 0 / 15%), 0 10px 0 -5px var(--bg-component),
    0 10px 1px -4px rgb(0 0 0 / 15%), 0 20px 0 -10px var(--bg-component),
    0 20px 1px -9px rgb(0 0 0 / 15%);
  cursor: pointer;
}

.card.single {
  cursor: pointer;
}

.scrollable-container .card:hover {
  transform: translateX(-1px) scale(1.01);
  box-shadow: 0 2px 2px 0 var(--box-shadow-1);
  transform-origin: center;
  transition: transform ease 300ms;
  filter: blur(0);
}

.scrollable-container .card.open {
  position: fixed;
  top: 50px;
  left: 50px;
  bottom: 50px;
  right: 50px;
  z-index: 333;
  transition: all ease-in-out 300ms;
}

.scrollable-container .card.card-inner {
  transition: transform ease 300ms;
}

.scrollable-container .card .card-header {
  border: none;
}

.scrollable-container .card .card-body {}

.scrollable-container .card:hover .action-items {
  top: 30%;
  transition: all ease 300ms;
}

.scrollable-container .card .action-items {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: space-evenly;
  top: 100%;
  padding: 20px 0;
  transition: all ease 300ms;
  flex-direction: column;
}

.scrollable-container .card .action-items a {
  border-radius: 50px;
  color: #fff;
  line-height: normal;
  background: #c32480;
  border: none;
  text-transform: capitalize;
  max-height: 30px;
  margin: 10px 15px;
}

.scrollable-container .card .card-footer {
  color: var(--white);
  background-color: rgba(74, 73, 157, 0.8);
  overflow: hidden;
  border-radius: 0 0 12px 12px;
}

.pie-wrapper {
  height: 5em;
  width: 5em;
  position: relative;
}

.pie-wrapper:nth-child(3n + 1) {
  clear: both;
}

.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 5em, 5em, 2.5em);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper .pie .half-circle {
  height: 100%;
  width: 100%;
  border: 0.2em solid var(--color-1);
  border-radius: 50%;
  clip: rect(0, 2.5em, 5em, 0);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper .label {
  border-radius: 50%;
  cursor: default;
  display: block;
  font-size: inherit;
  left: 0;
  line-height: 450%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  color: var(--light-text-color);
}

.pie-wrapper .shadow {
  height: 100%;
  width: 100%;
  border: 0.2em solid var(--light-text-color);
  border-radius: 50%;
}

.new-card {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  z-index: 1;
  background: #007bff;
  color: #fff;
  font-size: 30px;
  width: 70px;
  height: 70px;
  box-shadow: 0 5px 30px var(--color-1);
  transition: all ease-in-out 300ms;
}

.new-card:hover,
.new-card:focus,
.new-card:active {
  color: white;
  box-shadow: 0 5px 50px var(--color-1);
  transition: all ease-in-out 300ms;
  background-image: linear-gradient(154deg, #c32480 0, #4a499d 100%);
  transform: rotate(90deg);
}

/*customised css */

#geo_map path {
  stroke: var(--bg-component) !important;
  /* choose a color for the border */
}

#geo_map path {
  display: none;
}

#geo_map path:nth-child(n + 250) {
  display: initial;
}

.tab-pane.active .mid,
.tab-pane.active .right {
  display: none;
  pointer-events: none;
}

.custom-control-label {
  color: var(--text-color);
}

.custom-control-input:checked~.custom-control-label::before,
.badge-primary {
  background-color: var(--color-1);
  border-color: var(--color-1);
}

.badge {
  line-height: inherit;
}

body .react-tagsinput-input {
  max-height: 100%;
  line-height: 15px;
  color: inherit;
}

body .react-tagsinput-tag {
  background: var(--color-1);
  color: white;
  border: none;
  line-height: 14px;
}

[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.reveal {
  animation-duration: 1s;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
  animation-name: reveal;
  animation-timing-function: ease-in-out;
  animation-delay: 0ms;
}

.reveal:nth-child(1) {
  animation-delay: 0;
}

.reveal:nth-child(2) {
  animation-delay: 200ms;
}

.reveal:nth-child(3) {
  animation-delay: 400ms;
}

.reveal:nth-child(4) {
  animation-delay: 600ms;
}

.reveal:nth-child(5) {
  animation-delay: 800ms;
}

.reveal:nth-child(6) {
  animation-delay: 1000ms;
}

.reveal:nth-child(7) {
  animation-delay: 1200ms;
}

.reveal:nth-child(8) {
  animation-delay: 1400ms;
}

.reveal:nth-child(9) {
  animation-delay: 1600ms;
}

.reveal:nth-child(10) {
  animation-delay: 1800ms;
}

.reveal:nth-child(11) {
  animation-delay: 2000ms;
}

.reveal:nth-child(12) {
  animation-delay: 2200ms;
}

.reveal:nth-child(13) {
  animation-delay: 2400ms;
}

.reveal:nth-child(14) {
  animation-delay: 2600ms;
}

.reveal:nth-child(15) {
  animation-delay: 2800ms;
}

@keyframes reveal {
  0% {
    margin-left: -150%;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.loading {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: initial;
  background: linear-gradient(to right, #eeeeee 8%, #bbb 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 15px;
  border-radius: 20px;
  margin: 5px;
  position: relative;
  overflow: hidden;
}

.selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPickerInput input {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='calendar-alt' class='svg-inline--fa fa-calendar-alt fa-w-14 ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='grey' d='M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  padding-right: calc(1.5em + 0.75rem);
  background-position: center right 10px;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-radius: 30px;
}

.DayPickerInput-OverlayWrapper .DayPickerInput-Overlay {
  background: var(--bg-component);
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 15px var(--box-shadow-1), 0 1px 8px var(--box-shadow-1);
}

.right-align .DayPickerInput-Overlay {
  left: auto;
  right: 0;
}

table.fixedHeader {
  width: 100%;
  /* min-height: 300px; */
}

table {
  /* min-height: 300px; */
}

.fixedHeader thead,
.fixedHeader tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.fixedHeader tbody {
  display: block;
  overflow-y: auto;
  table-layout: fixed;
  max-height: 500px;
  width: calc(100% + 5px);
}

table.stickyHeader {
  position: relative;
}

table.stickyHeader thead th {
  position: sticky;
  top: 0;
  background-color: var(--bg-component);
  z-index: 10;
  border-bottom: 1px solid var(--border-component);
}

path.surface:hover {
  stroke: transparent !important;
}

#analysisTable thead th {
  white-space: nowrap;
  position: relative;
  min-width: 120px;
}

#analysisTable thead th .col-remove {
  position: absolute;
  top: auto;
  right: 10px;
  bottom: auto;
}

.treemap_highlighted {
  transition: all ease-in-out 300ms;
  transform: translate(0px, -5px) scale(0.95);
  outline: 2px solid var(--color-1);
}

.slice {
  transition: all ease-in 300ms;
}

.stepContainer {
  position: relative;
  margin: 0 auto;
}

.step {
  position: relative;
  list-style: none;
}

.step__item {
  position: relative;
  min-height: 75px;
  counter-increment: list;
  padding-left: 0.5rem;
  padding-top: 12px;
}

.step__item:before {
  content: "";
  position: absolute;
  left: -20px;
  top: 40px;
  bottom: -30px;
  width: 2px;
  border-left: 2px solid var(--border-color);
}

.step__item:after {
  content: counter(list);
  position: absolute;
  top: 15px;
  left: -2.5rem;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--bg-component);
  color: var(--text-color);
  font-weight: 400;
  font-size: 13px;
  line-height: 2rem;
  text-align: center;
  border: 1px solid var(--light-text-color);
}

.step__item:last-child:before {
  border: none;
}

.step__item.step__item--complete {
  opacity: 0.6;
  color: var(--text-color);
}

.step__item.step__item--complete:after {
  content: "\2713";
  font-weight: 400;
  background: var(--light-text-color);
  color: var(--white);
}

.step__item.step__item--complete:before,
.step__item.step__item--active:before {
  border-color: var(--color-8);
}

.step__item.step__item--active:after {
  background: var(--color-8);
  color: var(--white);
  border-color: white;
  box-shadow: 0 0 1px 2px var(--color-8);
}

.step__item .card {
  overflow: hidden;
  margin-left: 50px;
}

.step__item .card-body section {
  display: none;
}

.step__item.step__item--active .card-body section {
  display: block;
}

.step__item .categories .category {
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 5px;
  transition: none;
}

.step__item .categories .category-icon {
  margin: 50px auto 20px auto;
  font-size: 50px;
  color: var(--color-8);
  border-radius: 50%;
  background-color: rgba(175, 103, 164, 0.1);
  width: 100px;
  text-align: center;
  height: 100px;
  padding: 10px;
}

.step__item .categories .col {
  min-width: 220px;
  max-width: 220px;
}

.step__item .categories .category-name {
  text-transform: capitalize;
  text-align: center;
  color: var(--light-text-color);
  margin: 20px 0;
}

.step__item .categories {
  max-height: 50vh;
  overflow: auto;
}

.step__item .categories .category-state {
  display: none;
}

.step__item .categories .selected .category-state {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: var(--green-1);
  display: block;
}

.step__item .categories .category.selected {
  border: 2px solid var(--green);
}

.campaign_grid_body {
  overflow-y: auto;
  max-height: 75vh;
  overflow-x: hidden;
}

.campaign_grid .campaign_grid_body .campaign_grid_preview .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaign_grid .campaign_grid_body .campaign_grid_preview .name button {
  font-size: 15px;
  padding: 10px;
  box-shadow: none;
}

.campaign_grid .campaign_grid_body .campaign_grid_preview .icon {
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  background-color: var(--color-5);
  border-radius: 50%;
  font-size: 20px;
  padding: 10px 15px;
  margin: 15px;
}

.campaign_grid .campaign_grid_body .campaign_grid_preview .icon span {
  width: 17px;
  display: inline-block;
}

.auth-component {
  display: flex;
  flex-direction: column;
  text-transform: none;
  font-size: 14px;
  height: 100%;
  width: 100%;
}

.auth-component .top-section,
.auth-component .bottom-section {
  height: auto;
  display: flex;
  padding: 5em 8em;
  flex-direction: row;
  justify-content: space-around;
}

.auth-component .top-section {
  background-color: #1A1A1A;

}

.auth-component .top-section h1 {
  margin-bottom: 1.5em;
}

.auth-component .top-section h2 {
  margin: 0.6em 0;
  font-size: 1em;
  color: white;
}

.auth-component .top-section h1 img {
  width: 150px
}

.auth-component .top-section h3 {
  font-size: 2.3em;
  color: #CDD6FF;
  line-height: initial;
  margin: 0.6em 0;
}

.auth-component .bottom-section {
  background-color: #111;
  flex-grow: 1;

}

.auth-component .top-section h1.mobile-view {
  display: none;
}

.auth-component .top-section .top-section-lhs,
.auth-component .top-section .top-section-rhs {
  width: 50%;
  height: 100%;
  max-width: 50%;
}

.auth-component .top-section .top-section-rhs img {
  display: inline-block;
  max-height: 100%;
  max-width: 75%;
}

.auth-component form input {
  background: #111111;
  /* BW Shades/200 */

  border: 1px solid #CCCCCC;
  border-radius: 0.25rem;
  padding: 1rem;
  height: 4rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: white;
}

.auth-component button,
.auth-component a.btn,
.auth-modal button.btn-primary {
  padding: 1rem;
  background: #1614FF;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  color: #F0F3FF;
  margin: 10px 0;
  width: 100%;
  box-shadow: none;
  text-shadow: none;
  border: none;
}

.auth-component a {
  text-decoration: underline;
}

.auth-component input:-internal-autofill-selected,
.auth-component input:-internal-autofill-selected:hover,
.auth-component input:-internal-autofill-selected:focus,
.auth-component input:-internal-autofill-selected:active {
  background-color: initial !important;
  appearance: none;
  box-shadow: none !important;
}

.auth-modal .modal-header,
.auth-modal .modal-footer {
  border: none;
  color: white;
}

.auth-modal .modal-content {
  background-color: #666666;
  border-radius: 10px;
  border: none;
  color: white;
  text-align: center;
  text-transform: none;
}

.auth-component .bottom-section .form-container {
  max-width: 400px;
  width: 100%
}

@media (max-width:980px) {
  .auth-component {
    font-size: 12px;
  }

  .auth-component .top-section,
  .auth-component .bottom-section {
    display: flex;
    flex-direction: row;
    padding: 2em;
  }

  .auth-component .bottom-section .form-container {
    width: 100%;
  }

  .auth-component .top-section {
    flex-direction: column-reverse;
    padding: 3.5em;
  }

  .auth-component .top-section h1 {
    display: none;
  }

  .auth-component .top-section h1.mobile-view {
    display: block;
    text-align: left;
  }

  .auth-component .top-section .top-section-rhs img {
    max-width: 200px;
  }

  .auth-component .top-section h3 {
    font-size: 2em;
    margin-bottom: 0;
  }

  .auth-component .top-section .top-section-lhs,
  .auth-component .top-section .top-section-rhs {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin-bottom: 2em;
    text-align: center;

  }
}